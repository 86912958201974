import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
    // {
    //     path: '/login',
    //     component: () => import('@/views/Home'),
    //     hidden: true
    // },
    {
        path: '/',
        component: () => import('@/views/index'),
        name: '首页',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/historywater',
        component: () => import('@/views/historywater'),
        name: 'historywater',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/scancodejump',
        component: () => import('@/views/scancodejump/index'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/evaluate',
        component: () => import('@/views/evaluate/index'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/waterFeeTemplate',
        component: () => import('@/views/waterFeeTemplate'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
	{
	    path: '/gzBindPhoneTemplate',
	    component: () => import('@/views/gzBindPhoneTemplate'),
	    name: '',
	    meta: { title: '', icon: 'document', breadcrumb: false },
	    children: [
	    ]
	},
    {
        path: '/bindWechatOpenIdAndPhone',
        component: () => import('@/views/bindWechatOpenIdAndPhone'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/waterFeeHistory',
        component: () => import('@/views/waterFeeHistory'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
	{
	    path: '/gzBindPhoneSuccess',
	    component: () => import('@/views/gzBindPhoneSuccess'),
	    name: '',
	    meta: { title: '', icon: 'document', breadcrumb: false },
	    children: [
	    ]
	},
    {
        path: '/authentication',
        component: () => import('@/views/authentication/index'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/faceRecognition',
        component: () => import('@/views/authentication/faceRecognition'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/facialComparison',
        component: () => import('@/views/authentication/authentication'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/authSuccess',
        component: () => import('@/views/authentication/authSuccess'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
    {
        path: '/authErr',
        component: () => import('@/views/authentication/authErr'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    },
	{
	    path: '/gzTicketInfo',
	    component: () => import('@/views/gzTicketInfo'),
	    name: '',
	    meta: { title: '', icon: 'document', breadcrumb: false },
	    children: [
	    ]
	},
    {
        path: '/successfulCertification',
        component: () => import('@/views/successfulCertification'),
        name: '',
        meta: { title: '', icon: 'document', breadcrumb: false },
        children: [
        ]
    }

]

const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export default router
