import axios from 'axios'
const settings = require('@/settings')
let token=localStorage.getItem('authenticationToken')
const service = axios.create({
  baseURL: settings.BASE_URL,
  timeout: '5000',
  headers: {
    'ContentType': 'application/x-www-form-urlencoded',
    "Authorization": token? `Bearer ${token}` : '',
  },
})

service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
