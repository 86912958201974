import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import request from '@/api/index.js'

export default new Vuex.Store({
  getters: {

  },
  state: {

  },
  mutations: {

  },
  actions: {
    
  },
  modules: {

  },
})
