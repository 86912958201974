import { render, staticRenderFns } from "./svgicon.vue?vue&type=template&id=1e81c145&scoped=true&"
import script from "./svgicon.vue?vue&type=script&lang=js&"
export * from "./svgicon.vue?vue&type=script&lang=js&"
import style0 from "./svgicon.vue?vue&type=style&index=0&id=1e81c145&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e81c145",
  null
  
)

export default component.exports