<template>
  <div id="app">
    <router-view :key="key" />
  </div>
</template>

<script>
/**
 *
 * @description
 * @author Henson
 * @date 2022/08/30 17:02:09
 * @property {String} name detail
 * @event
 */

export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {

    }
  },
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + new Date()
        : this.$route + new Date();
    },
  },
  watch: {},
  created() {
  },
  mounted() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>

</style>
