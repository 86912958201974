import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局样式
import '@/assets/index.css'
// svg图标
import '@/assets/icons/index.js'

// 按需引入vant组件
import {Overlay, Popover,Image as VanImage, NoticeBar,Row ,Rate,Col,Dialog,Popup,Icon, Step, Steps, Pagination, Form, Field, Button, Tab, Tabs,Search ,Toast, Loading} from 'vant'
Vue.use(Overlay)
Vue.use(Popover)
Vue.use(VanImage)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(NoticeBar)
Vue.use(Col);
Vue.use(Dialog)
Vue.use(Row)
Vue.use(Rate)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Search)
Vue.use(Toast)
Vue.use(Loading)

Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.config.productionTip = false

import VueResource from 'vue-resource'
Vue.use(VueResource)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
